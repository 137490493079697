import { EntityId } from '@reduxjs/toolkit';
import { SxProps } from '@mui/material/styles';
import { SortFn, Sort, DragTypes } from '@utils/types';
import { File } from '@features/files/types';
import { FilesWithSelectedFlagType } from '@hooks/useGetFilesWithSelectedFlag';
import { ApolloQueryResult } from '@apollo/client';

export type ListDataType = string | string[] | number | boolean | undefined;
export type ListEntity = { [key: string]: ListDataType } & { file_id?: EntityId };

export enum ListColumnDefKeys {
  CONTENT_TYPE = 'content_type',
  OWNER_NAME = 'owner_name',
  NAME = 'name',
  DESCRIPTION = 'description',
  ACCESS_STATUS = 'access_status'
}

export interface ListColumnDef {
  key: string;
  headerName: string;
  headerCustomStyles?: SxProps;
  component?: (value: ListColumnProps) => JSX.Element;
  dataFormatter?: (data: ListDataType) => string;
  editable?: boolean | ((data: ListEntity) => boolean);
  onDataChange?: (newData: ListDataType, id: EntityId) => void;
  sortable?: boolean;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
}

export type ListSortChangeHandler = (sort: Sort) => void;
export type ListRowSelectHandler = (ids: EntityId[], selected: boolean, index?: number) => void;
export type ListSelectAllRowsHandler = (selected: boolean) => void;
export type ListPageChangeHandler = (newPageNumber: number) => void;
export type ListRowClickHandler = (data: File) => void;
export type ListRefreshHandler = () => void;

export interface ListDndSettings {
  getType: (data: ListEntity) => typeof DragTypes.ELEMENT | typeof DragTypes.DIRECTORY;
  onDrop: (dragId: EntityId, hoverId: EntityId) => void;
}

export interface ListSortSettings {
  initial: Sort;
  fn: SortFn;
}

export interface VirtualizedListItemViewProps {
  data: {
    files: FilesWithSelectedFlagType[];
    selectedIds: EntityId[];
    onSelect: (ids: EntityId[], selected: boolean, index?: number) => void;
    readOnly?: boolean;
    onRowClick: (file: ListEntity) => void;
    isSelectable?: boolean;
    columns: ListColumnDef[];
    dndEnabled: boolean;

    saveScrollPosition?: () => void;
    dndSettings?: ListDndSettings;
  };
  index: number;
  style: React.CSSProperties;
}

export interface ListProps {
  data: File[];
  columns: ListColumnDef[];
  totalItems?: number;
  onRowSelect: ListRowSelectHandler;
  onRowClick: ListRowClickHandler;
  onRefresh?: ListRefreshHandler;
  setScrollPosition: (newScrollPosition: number) => void;
  saveScrollPosition?: () => void;
  setParentPageNumber?: (newPageNumber: number) => void;
  dndEnabled?: boolean;
  dndSettings?: ListDndSettings;
  actions?: JSX.Element;
  isSelectable?: boolean;
  isPageSizeSelectable?: boolean;
  readOnly?: boolean;
  onLoadMore: (startIndex: number, stopIndex: number) => Promise<void | ApolloQueryResult<any>>;
}

export interface ListHeadRowProps {
  sort: Sort;
  columns: ListColumnDef[];
  allRowsSelected: boolean;
  anyRowSelected: boolean;
  onSelectAll: ListSelectAllRowsHandler;
  onSortChange: ListSortChangeHandler;
  isSelectable?: boolean;
}

export interface ListRowProps {
  id: EntityId;
  data: ListEntity;
  columns: ListColumnDef[];
  index: number;
  editMode?: boolean;
  selected: boolean;
  onSelect: ListRowSelectHandler;
  onClick: ListRowClickHandler;
  saveScrollPosition?: () => void;
  onEditingStart?: () => void;
  onEditingEnd?: () => void;
  dndEnabled: boolean;
  dndSettings?: ListDndSettings;
  isSelectable?: boolean;
  readOnly?: boolean;
}

export interface ListBaseColumnProps {
  column: ListColumnDef;
}

export interface ListHeaderColumnProps extends ListBaseColumnProps {
  activeSort: Sort;
  onSortChange: ListSortChangeHandler;
}

export interface ListColumnProps extends ListBaseColumnProps {
  id: EntityId;
  data: ListEntity;
  editMode?: boolean;
  onEditingStart?: () => void;
  onEditingEnd?: () => void;
  onClick: ListRowClickHandler;
  saveScrollPosition?: () => void;
  readOnly?: boolean;
}
